import React from "react";

const Forms = () => {
  return (
    <div className="min-h-screen flex flex-col  bg-red-900 p-5">
      {/* <img src="/images/logo-2.png" alt="" className="mb-5" /> */}
      
      <form className="flex-grow mt-20">
        <div className="grid gap-5">
          <div className="w-full">
            <input
              type="text"
              id="first_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Name"
              required
            />
          </div>
          <div>
            <input
              type="email"
              id="last_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Email"
              required
            />
          </div>
          <div>
            <input
              type="number"
              id="company"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Phone"
              required
            />
          </div>
          <div>
            <input
              type="text"
              id="phone"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Location"
              required
            />
          </div>

          <select
            id="projects"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option selected>Choose Project</option>
            <option value="CC">Crystal Crown</option>
            <option value="EX">Excellence</option>
            <option value="HV">High Vision</option>
            <option value="IN">Innovate</option>
          </select>

          <select
            id="medium"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option selected>Choose Medium</option>
            <option value="Digital">Digital Marketing</option>
            <option value="Print">Print Media</option>
            <option value="Social">Social Media</option>
            <option value="TV">Television</option>
          </select>

          <select
            id="managers"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option selected>Choose Manager</option>
            <option value="John">John Doe</option>
            <option value="Jane">Jane Smith</option>
            <option value="Mark">Mark Johnson</option>
            <option value="Emily">Emily Davis</option>
          </select>

          <button
            type="submit"
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
          >
            Submit
          </button>
        </div>
      </form>

      <div className="text-white text-center -mb-5 p-2 rounded-md">
        <h1><strong>Powered By:</strong> <a href="https://www.abarissoftech.com/">Abaris Softech</a></h1>
      </div>
    </div>
  );
};

export default Forms;
