import React from "react";

const Home = () => {
  return (
    <section>
      <img className="h-full" src="/images/banner1.jpg" />
      <div className="bg-orange-100">
        <h1 className="text-center text-2xl md:text-4xl font-semibold py-5">Why Choose Us</h1>
        <div className="grid grid-cols-2 md:grid-cols-4 py-5 gap-5">
          <div className="border-r border-gray-800 flex flex-col justify-center items-center">
            <img className="w-12" src="/images/trust-alt.png" alt="" />
            <h1 className="text-gray-600 md:text-2xl font-semibold pt-2 text-center">
              Trusted Developer
            </h1>
          </div>
          <div className="md:border-r border-gray-800 flex flex-col justify-center items-center">
            <img className="w-12" src="/images/shipping-timed.png" alt="" />
            <h1 className="text-gray-600 md:text-2xl font-semibold pt-2 text-center">
              On Time Delivery
            </h1>
          </div>
          <div className="border-r border-gray-800 flex flex-col justify-center items-center">
            <img className="w-12" src="/images/career-growth.png" alt="" />
            <h1 className="text-gray-600 md:text-2xl font-semibold pt-2 text-center">
              Growth Corridor
            </h1>
          </div>
          <div className=" flex flex-col justify-center items-center">
            <img className="w-12" src="/images/house-chimney.png" alt="" />
            <h1 className="text-gray-600 md:text-2xl font-semibold pt-2 text-center">
              Vaastu Compliant
            </h1>
          </div>
        </div>
      </div>
      <h1 className="text-2xl md:text-4xl font-semibold text-center py-5 md:py-10">
        Location Advantages
      </h1>

      <div className="grid md:grid-cols-3 py-5 gap-5 mx-5 text-sm text-center">
        <div className="bg-orange-100 flex flex-col items-center p-5 h-48 shadow-md rounded-md border border-gray-400">
          <img className="" src="/images/intersection.png" alt="" />
          <h1 className="text-gray-600 text-2xl font-semibold pt-2">
          10 minutes from Medavakkam.
          </h1>
        </div>
        <div className="bg-orange-100 flex flex-col  items-center p-5 h-48 shadow-md rounded-md border border-gray-400">
          <img className="" src="/images/bus-stop.png" alt="" />
          <h1 className="text-gray-600 text-2xl font-semibold pt-2">
          15 minutes from Siruseri.
          </h1>
        </div>
        <div className="bg-orange-100 flex flex-col  items-center p-5 h-48 shadow-md rounded-md border border-gray-400">
          <img className="" src="/images/bus.png" alt="" />
          <h1 className="text-gray-600 text-2xl font-semibold pt-2">
          New Kilambakkam Bus Terminus
          - 20 Mins
          </h1>
        </div>
        <div className="bg-orange-100 flex flex-col  items-center p-5 h-48 shadow-md rounded-md border border-gray-400">
          <img className="" src="/images/hospital.png" alt="" />
          <h1 className="text-gray-600 text-2xl font-semibold pt-2">
          Global Health City
          - 15 Mins
          </h1>
        </div>
        <div className="bg-orange-100 flex flex-col  items-center p-5 h-48 shadow-md rounded-md border border-gray-400">
          <img className="" src="/images/school.png" alt="" />
          <h1 className="text-gray-600 text-2xl font-semibold pt-2">
          SBIOA International School
          - 10 Mins
          </h1>
        </div>
        <div className="bg-orange-100 flex flex-col  items-center p-5 h-48 shadow-md rounded-md border border-gray-400">
          <img className="" src="/images/bus-2.png" alt="" />
          <h1 className="text-gray-600 text-2xl font-semibold pt-2">
          Ponmar Bus stop
          - 2 Mins
          </h1>
        </div>
      </div>
      
    </section>
  );
};

export default Home;
