import "./App.css";
import Home from "./components/Home";
import Forms from "./components/Home/Forms";

function App() {
  return (
    <>
    <div className="flex md:flex-row flex-col ">
      {/* <h1 className="text-9xl text-gray-500 animate-ping  duration-500">hello</h1> */}
      <div className="md:w-[75%] ">
        <Home />
      </div>
      <div className="md:w-[25%] md:fixed md:right-0 md:h-full ">
        <Forms />
      </div>
    </div>
      
    </>
  );
}

export default App;
